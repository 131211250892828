.espace_membres{
  main{
    background-color: #f9faff;
  }
  h1{
    position: relative;
    margin-bottom: 20px;
    &::after{
      content: "";
      display: block;
      width: 40px;
      height: 4px;
      background-color: $primary;
      margin-top: 10px;
    }
  }
  form.adherent__form{
    padding-bottom: 3rem;
    margin-bottom: 1rem;
    .required{
      color: $red;
    }
    .error{
      background-color: $red-100;
      color: $red;
      padding: 0.5em;
      margin-top: 10px;
      border-radius: 5px;
      font-size: 80%;
    }
    #id_civilité-titre{
      display: flex;
      gap: 30px;
      label{
        font-weight: normal;
      }
    }
    fieldset{
      margin-bottom: 20px;
      border-radius: 5px;
      background: #fff;
      border-radius: 10px;
      border: 1px solid #ced6e0;
      padding: 35px;
      legend{
        color: $primary;
        border-bottom: 1px solid #ced6e0;
        margin-bottom: 30px;
      }
    }
    .controls{
      margin-bottom: 20px;
      .help-block{
        margin-top: 10px;
      }
    }
    label{
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: 500;
      color: #1a3b5d;
      width: 100%;
      display: block;
      user-select: none;
      font-weight: bold;
      margin-bottom: 10px;
    }
    input{
      &[type="email"],
      &[type="text"]{
        position: relative;
        display: block;
        width: 100%;
        height: 38px;
        border: 1px solid #e6e6ea;
        border-radius: 5px;
        font-size: 16px;
        padding: 10px 20px;
        transition: all 500ms ease;
        &:focus,
        &:hover{
          border-color: #e41e2f !important;
        }
      }
    }
    .form-group{
      margin-bottom: 10px;
    }
    .form__footer{
      display: flex;
      justify-content: space-between;
    }
    // .options{
    //   label{
    //     display: block;
    //     margin-bottom: 20px;
    //   }
    // }
  }
}

.alert{
  &-error{
    background-color: $red-100;
    color: $red;
  }
}

.help-block{
  font-size: 13px;
}

.content__member{
  padding-top: 3rem;
}

.input-icon {
  position: relative;
}

.input-icon>i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

.input-icon>input {
  padding-left: 25px;
  padding-right: 0;
}

.input-icon-right>i {
  right: 0;
}

.input-icon-right>input {
  padding-left: 0;
  padding-right: 25px;
  text-align: right;
}

.dl{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  dd{
    font-family: $roboto_black;
    font-size: 20px;
  }
  &.last{
    dd{
      font-size: 30px;
    }
  }
}

.text-right{

  .dl{
    justify-content: space-between;
    &.last{
      justify-content: flex-end;
    }
  }
}

.dons__wrapper{
  @include media-breakpoint-up(lg) {

  }
  em{
    line-height: 1;
  }
}

.img__field{
  display: block;
  width: 100px;
  height: 160px;
  // border: 1px solid #eee;
  border-radius: 5px;
  position: relative;
  transition: all .3s ease;
  &::after{
    content: "";
    display: block;
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(60%);
    mix-blend-mode: multiply;


  }
  &.img-option-newsletter{
    &::after{
      background-image: url(../img/member/newsletter.svg);
      width: 157px;
      height: 81px;
    }
  }
  &.img-option-publications-de-l-annee-standard,
  &.img-option-publications-de-l-annee-etranger{

    &::after{
      background-image: url(../img/member/newsletter_paper.svg);
      width: 93px;
      height: 73px;
    }
  }
  &.img-option-toutes-les-publications-standard, .img-option-toutes-les-publications-etranger{
    &::after{
      background-image: url(../img/member/newsletter__all.svg);
      width: 153px;
      height: 101px;
    }
  }
  &.img-cb{
    &::after{
      background-image: url(../img/member/cb.svg);
      width: 83px;
      height: 54px;
    }
  }
  &.img-chèque{
    &::after{
      background-image: url(../img/member/cheque.svg);
      width: 83px;
      height: 55px;;
    }
  }
  &.img-prélèvement{
    &::after{
      background-image: url(../img/member/prelevement.svg);
      width: 83px;
      height: 98px;
    }
  }
  &.img-virement{
    &::after{
      background-image: url(../img/member/virement.svg);
      width: 83px;
      height: 64px;
    }
  }
}
#id_options-options{

  span:last-child{
    margin-top: auto;
    padding-top: 20px;
    border-top: 1px solid #eee;
  }

}

#id_options-options,
#id_résumé-moyen_de_paiement{
  label{
    display: grid;
    grid-template-columns: 120px 1fr;
    align-items: center;
    gap: 1rem;
    border: 1px solid $secondary;
    padding: 30px;
    border-radius: 4px;
    font-weight: normal;
    position: relative;
    cursor: pointer;
    height: 100%;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    input{
      display: none;
    }
    .checkbox{

      width: 30px;
      height: 30px;
      display: block;
      border-radius: 3px;
      border: 1px solid $secondary;
      z-index: 11;
      position: absolute;
      top: 10px;
      left: 10px;
      transition: all .3s ease;
      background-color: white;

      &::after{
        position: absolute;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        content: '\f00c';
        top: 4px;
        left: 8px;
        transition: all .3s ease;
        opacity: 0;
        transform: scale(0);
        transform-origin: center;
        visibility: hidden;
        color: $secondary;
        z-index: 1;
      }
    }
    span.title{
      font-size: 18px;
      text-transform: uppercase;
      display: block;
      font-family: $roboto_bold;
      span{
        font-size: 16px;
        text-transform: none;
        font-family: $roboto;
      }
      &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 2px solid;
      }
    }
    .price{
      font-size: 27px;
      font-family: $roboto_black;
      display: block;
      margin-top: 20px;
      text-align: right;
    }


    input:checked + .checkbox{
      border-color: $secondary;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      background-color: $primary;
      border-color: $primary;
      &::after{
        transform: scale(1);
        visibility: visible;
        opacity: 1;
        color: white;
      }
    }
    input:checked ~ span{
      color: $primary;
    }
    input:checked ~ .img__field{
      border-color: $primary;

    }
  }
}


#id_résumé-moyen_de_paiement{
  display: grid;
  text-align: center;
  gap: 1rem;
  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media-breakpoint-only(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  .img__field{
    height: 92px;
  }
  label{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px !important;
  }
  .img__field{
    margin: 0 auto;
  }
  .checkbox{
    border-radius: 50% !important;
  }
  .title{
    font-size: 16px !important;
    font-family: $roboto !important;
    text-transform: inherit !important;
  }
}


.boxes__wrapper{
  display: grid;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
  gap: 2rem;
  .icon__wrapper{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background-color: $primary;
    color: white;
    margin-bottom: 40px;
    margin-top: 20px;
    font-size: 20px;
    position: relative;
    &::before{
      position: absolute;
      content: '';
      background: rgba($white, 0.1);
      width: 90px;
      height: 90px;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transition: all 900ms ease;
      transform: translate(-50%,-50%);
    }
  }
  .h2{
    font-size: 20px;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid lighten($secondary, 10);
    transition: all 900ms ease;
  }
  a{
    text-decoration: none;
  }
  .box{
    padding: 2rem;
    border: 1px solid #eee;
    border-radius: 10px;
    text-align: center;
    color: inherit;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
    background-color: $secondary;
    color: white;
    &:hover{
      .h2{
        color: $primary;
      }
      .icon__wrapper{
        &::before{
          height: 100px;
          width: 100px;
        }
      }
    }
    &.disabled{
      opacity: .5;
      pointer-events: none;
    }
  }
  span.text{
    margin-left: 5px;
    display: inline-block;
  }
}


.membres__space{
  h1{
    font-size: 24px;
    margin-top: 2rem;
  }
}

input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  &:focus+label {
    outline: 1px dotted $cyan;
    outline: -webkit-focus-ring-color auto 5px;
  }
}

input[type="file"]+label {
  color: white !important;
  background-color: $black;
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border-radius: 3px;
}


#id_options-options{

  >div{
    flex: 1 1 0px;
    position: relative;
    h2{
      font-size: 20px;
    }
  }
}



#id_cotisation-adhésion{
  label{

    position: relative;

    span{
      display: inline-block;
      padding: 20px;
      padding-left: 40px;
      border-radius: 5px;
      background-color: $white;
      width: 100%;
      border: 2px solid $secondary;
      cursor: pointer;
    }
    input{
      position: absolute;
      left: 15px;
      top: 22px;
    }
    input[type="radio"]:checked + span {

      background-color: rgba($blue, .1);
    }
  }
}



.fieldset{
  position: relative;
  display: block;
  padding: 40px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
  margin-bottom: 30px;
  z-index: 1;
  &:last-child{
    margin-bottom: 100px;
  }
  legend{
    font-family: $roboto_bold;
    color: $primary;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 20px;
    &::after{
      position: absolute;
      content: '';
      width: 40px;
      height: 4px;
      left: 0px;
      bottom: 0;
      background: $secondary;
      border-radius: 2px;
      opacity: .3;
    }
  }

  .form-group{
    margin-bottom: 15px;
    label{
      font-weight: bold;
    }
  }
}

.required{
  color: $primary;
  font-size: 80%;
}
.form-action{
  position: fixed;
  z-index: 99;
  bottom: 0;
  background-color: $secondary;
  height: 100px;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #eee;
  a{
    color: white;
  }
}
