.main__header{
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  transition: all 500ms ease;
  border-bottom: 1px solid $light;
  .icon{
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    &-search{
      background-image: url(../img/search.svg);
    }
    &-space_adherent{
      background-image: url(../img/user.svg);
    }
    &-subscribe{
      background-image: url(../img/subscribe.svg);
    }
    &-pro{
      background-image: url(../img/pro.svg);
    }
  }
}





.header__topbar{
  height: 40px;
  background-color: $secondary;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  overflow: hidden;
  ::placeholder {
    color: rgba(white, .3);
    font-size: .9rem;
  }
  a{
    color: white;
    &.nav-link{
      display: inline-flex;
      gap: .5rem;
      align-items: center;
    }

  }
  .search__form{
    background-color: #353C53;
    color: white;
    input.form-control , .btn{
      background-color: transparent;
      border: 0;
      color: white;
    }
    .btn{
      &:hover{
        background-color: $secondary;
      }
    }
  }
}
.afdiag__logo{
  img{
    width: 150px;
    height: auto;
  }
}
.header__nav{
  padding:25px 10px;
  gap: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 10px;
    li{
      a{
        color: $text_color;
        text-decoration: none;
        font-family: $roboto_bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: all .3s ease;
        font-size: 14px;
        &:hover{
          color: $primary;
        }
      }
    }
  }
  .dropdown-menu{
    background: var(--bs-white);
    @include media-breakpoint-up(xl) {
      border-radius: 5px;
      padding: 10px 30px;
      box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.10) inset, 0px 50px 100px -20px rgba(50, 50, 93, 0.25), 0px 30px 60px -30px rgba(0, 0, 0, 0.30);
    }
    @include media-breakpoint-down(xl) {
      border: 0;
      padding-left: 10px;
      background-color: #eee;
      border-radius: 0;
    }
    .dropdown-item {
      display: block;
      padding: 10px 0px;
      &:not(:last-child){
        border-bottom: 1px dashed $light;
      }
      &:hover{
        background-color: white;
      }
    }
  }

}

.sticky-header {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  background: #ffffff;
  border-bottom: 1px solid rgba(37,38,56,0.1);
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 0;
  transition: all 500ms ease;
}
.fixed-header .sticky-header {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  animation-name: fadeInDown;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}



.dropdown-item {
  @include media-breakpoint-down(lg) {
    white-space: inherit;
  }
}
.navbar-nav{
  @include media-breakpoint-down(xl) {
    margin-bottom: 20px !important;
    li{
      border-bottom: 1px solid #eee;
      a{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}



.header--member_area{
  .btn{
    font-size: 80%;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 8px;
    padding-right: 8px;
  }
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .btn{
      display: block;
      width: 100%;
    }
  }
}


@media screen and (min-width: 1199px) and (max-width:1272px) {
  .header__nav{

    padding: 20px 20px;
    align-items: flex-end;
    .navbar-collapse{
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 10px;
      .navbar-nav{
        &.me-auto{
          margin-right: unset !important;
        }
      }
      .header--member_area{
      }
    }
  }
}
