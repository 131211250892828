@use "sass:map";
$fa-font-path: "../fonts/fontawesome";
$slick-font-path:'../fonts/';
$slick-loader-path:'../img/';


$primary: #E30613;
$secondary: #1D2130;
$success: #198754;
$info: #0dcaf0;
$danger: #dc3545;
$warning: #ffc107;
$lighten: #e1e1e5;
$light: #D2DEE3;
$dark: #222;

$text_color: #274C5B;
$body-color: $text_color;
$facebook: #3b5998;
$linkedin : #0376A8;
$twitter: #00aced;
$google: #dd4b39;
$pinterest: #cb2027;
$instagram: #3f729b;
$tripadvisor: #589442;
$flickr: #0063dc;
$youtube: #cd201f;
$instagram: #2f598b;
$white: #fff;

//btn

$btn-border-radius: 4px;
$btn-padding-y: 8px;
$btn-padding-x: 16px;

//input
$input-placeholder-color: #a1a1a1;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "white": $white,
  "light": $light,
  "dark": $dark,
);

@font-face {
  font-family:"Roboto Bold";
  src:url("../fonts/Roboto-Bold.woff2") format("woff2"),
  url("../fonts/Roboto-Bold.woff") format("woff");
  font-style:normal;
  font-weight:400;
}

@font-face {
  font-family:"Roboto Regular";
  src:url("../fonts/Roboto-Regular.woff2") format("woff2"),
  url("../fonts/Roboto-Regular.woff") format("woff");
  font-style:normal;
  font-weight:400;
}
@font-face {
  font-family:"Roboto Black";
  src:url("../fonts/Roboto-Black.woff2") format("woff2"),
  url("../fonts/Roboto-Black.woff") format("woff");
  font-style:normal;
  font-weight:400;
}

$roboto: "Roboto Regular";
$roboto_bold: "Roboto Bold";
$roboto_black: "Roboto Black";

$font-family-base: $roboto;
$font-size-base: 1rem;
$headings-font-family: $roboto_bold !default;
