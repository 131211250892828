.footer__site{
  padding-top: 4rem;
  background-color: $secondary;
  color: white;
  hr{
    border-color: lighten($secondary, 15);
    margin-top: 2rem;
    margin-bottom: 2rem;
    opacity: 1;
  }
  nav{
    a{
      color: white;
      text-decoration: none;
      font-weight: 500;
      &:hover{
        color: $primary;
      }
    }
  }
  .copyrights{
    margin-top: 3rem;
    font-size: 90%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: lighten($secondary, 5);
    &, a, .nav-link{
      color: white;
    }
    a{
      &:hover{
        color: $primary;
      }
    }
    img{
      filter: invert(1);
    }
  }
}


.social__links{
  gap: 2rem;
  display: flex;
  a{
    color: white;
  }
  i{
    font-size: 26px;
  }
}
