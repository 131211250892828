.row-background {
    padding-top: 15px;
    padding-bottom: 15px;

    h2:first-child,
    h3:first-child,
    h4:first-child, {
        margin-top: 0;
    }

    a:hover {
        text-decoration: underline;
    }
}

.column-background {
    padding: 15px;
    margin-bottom: 15px;
    overflow: hidden;

    p:last-child {
        margin-bottom: 0;
    }

    a:hover {
        text-decoration: underline;
    }
}

.white-background {
    //color: $text-color;
    background-color: $white;
}

.column-spacer {
    width: 100%;
}

.column-spacer-sm {
    height: 2em;
}

.column-spacer-md {
    height: 4em;
}

.column-spacer-lg {
    height: 8em;
}
