


.home__carousel{
  color: white;
  @include media-breakpoint-up(lg) {
    overflow: hidden;

  }

  .slide{
    position: relative;
  }
  .item-bg{
    height: 70vh;
    max-height: 700px;
    background-size: cover;
    background-position: center center;
    background-color: #666;
    background-blend-mode: overlay;
    &::before{
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;bottom: 0;
      background: linear-gradient(339deg, rgba(29,33,45,0) 0%, rgba(29,33,45,1) 100%);
    }
    @include media-breakpoint-down(lg) {
      height: 50vh;
    }
  }
  .slide-overlay {
    position: absolute;
    @include media-breakpoint-up(lg) {
      top: 50%;
      left: 200px;
      transform: translateY(-50%);
      max-width: 50%;
    }

    @include media-breakpoint-down(lg) {
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      background-color: $secondary;
      p{
        display: none;
      }
    }
    h1,h2{
      position: relative;
      @include media-breakpoint-up(lg) {
        font-size: 64px;
        padding-bottom: 2rem;
        &::after{
          content: "";
          background-color: $primary;
          height: 8px;
          width: 40px;
          display: block;
          border-radius: 4px;
          margin-top: 30px;
        }
      }
      @include media-breakpoint-down(lg) {
        font-size: 22px;
      }
    }
  }
  .dots{
    @include media-breakpoint-up(lg) {
      bottom: 90px;
    }
    @include media-breakpoint-down(lg) {
      bottom: -60px;
    }
  }
  .slick-arrow{
    span{
      background-image: url(../img/arrow.svg);
      @include media-breakpoint-up(lg) {
        width: 30px;
        height: 53px;
      }
      @include media-breakpoint-down(lg) {
        width: 18px;
        height: 20px;
        background-size: contain !important;
      }
    }
  }
}


.section{
  padding-top: 100px;
  padding-bottom: 100px;
  h2{
    font-size: 48px;
  }
  @include media-breakpoint-down(lg) {
    padding: 20px;
  }
}


.who_are_we{
  background-image: url(../img/epi.svg);
  background-repeat: no-repeat;
  background-position: 100px 100px;
  background-size: 200px;
  .teasers{
    display: grid;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(lg) {
      gap: 2rem;
      margin-top: 4rem;
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-down(lg) {
      padding: 30px;
      gap: 3rem;
    }
    .teaser{
      &-content{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      &__icon{
        display: inline-flex;
        width: 60px;
        height: 60px;
        background-color: $primary;
        border-radius: 10px;
        position: absolute;
        z-index: 1;
        top: -30px;
        left: 30px;
        background-repeat: no-repeat;
        background-position: center;
        &.notify{
          background-image: url(../img/notify.svg);
        }
        &.defend{
          background-image: url(../img/shield.svg);
        }
        &.support{
          background-image: url(../img/support.svg);
        }
      }
    }
  }
}


.grid{
  display: grid;
  gap: 2rem;
  &__2{
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__3{
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__4{
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}


.daily{
  background-color: rgba($primary, .1);
  background-image: url(../img/epi.svg);
  background-repeat: no-repeat;
  background-position: right 100px;
  background-size: 200px;
  background-blend-mode: overlay;
  .teasers{
    margin-top: 4rem;
  }
  @include media-breakpoint-down(lg) {

  }
}


.recipe{
  background: #F5F5F5;
  border-radius: 12px;
  text-align: center;
  display: block;
  text-decoration: none;
  color: inherit;
  &,img{
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .recipe__title{
    padding: 2rem;
    font-family: $roboto_bold;
    min-height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}


.events__list{
  display: grid;
  gap: 1rem;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
  margin-top: 30px;
  padding-bottom: 5rem;
  .event{
    .event_wrap{
      position: relative;
    }
    &:nth-child(2){
      margin-top: 3rem;
    }
  }
  .event__content{
    background: #FFFFFF;
    box-shadow: 0px 20px 35px rgba(167, 167, 167, 0.25);
    border-radius: 12px;
    padding: 2rem;
    // position: absolute;
    // bottom: -100%;
    // left: 20px;
    // right: 20px;
    margin-top: -40px;
    position: relative;
    z-index: 1;
    margin-left: 10px;
    margin-right: 10px;
  }
  .event__category{
    a{
      text-decoration: none;
      color: inherit;
      font-style: italic;
    }
  }
  h3{
    font-size: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    a{
      text-decoration: none;
      color: inherit;
    }
  }
  .event__date{
    color: $primary;
    font-family: $roboto_bold;
    font-size: 18px;
    span{
      display: inline-block;
      margin-right: 0.2rem;
    }
  }
  img{
    border-radius: 12px;
  }
}


.reasons{
  background-color: #F5F5F5;
  overflow: hidden;
  .container-fluid{
    max-width: 1400px;
  }

  .slick-list{
    overflow: inherit;
  }
  &__carousel{
    .wrapper{
      @include media-breakpoint-up(lg) {
        padding: 40px;
      }
      @include media-breakpoint-down(lg) {
        padding: 10px;
      }
    }
  }

}
.reason{
  display: flex;
  @include media-breakpoint-up(lg) {
    padding: 20px;
  }
  @include media-breakpoint-down(lg) {
    padding: 10px;
    flex-direction: column-reverse;
  }
  gap: 1rem;
  align-items: center;
  flex-shrink: 0;
  min-width: 100%;
  position: relative;
  max-width: 500px;
  border-radius: 5px;
  background: var(--white, #FFF);
  box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.10) inset, 0px 50px 100px -20px rgba(50, 50, 93, 0.25), 0px 30px 60px -30px rgba(0, 0, 0, 0.05);


  img{
    max-width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 4px;
  }
  h3{
    font-size: 20px;
  }
  >div:first-child{
    @include media-breakpoint-up(lg) {
      padding-left: 4rem;
      width: 80%;
    }
    &::before{
      position: absolute;
      left: 10px;
      content: '0' attr(data-counter);
      color: $primary;
      font-family: $roboto_bold;
      font-size: 50px;
      opacity: .1;
    }
  }
}


.banner{
  height: 300px;
  background-color: darken(white, 30);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
  .top__img{
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    flex-direction: column;
    &::before{
      position: absolute;
      content: '';
      background: -webkit-linear-gradient(90deg, rgba(37,38,56,0.8)15%, rgba(0,0,0,0.3) 100%);
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;

    }
    >*{
      position: relative;
      z-index: 1;
    }
  }
  h1{
    color: white;
    font-size: 46px;
  }
  .agenda_event__date{
    background-color: $primary;
    color: white;
    font-family: $roboto_black;
    display: inline-flex;
    padding: 1rem 2rem;
    border-radius: 10px;
    font-size: 20px;
    span{
      display: inline-block;
      &:not(:last-child){
        margin-right: 3px;
      }
    }
  }
}

.gallery{
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @include media-breakpoint-only(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  .gallery__item{
    display: block;
    background-color: $secondary;
    border-radius:4px;
    position: relative;
    .gallery__img{
      max-width: 100%;
      height: auto;
      border-radius:4px;
      transition: all .3s ease;
    }
    i{
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      transform-origin: center;
      font-size: 30px;
      color: white;
      opacity: 0;
      transition: all .3s ease;
    }
    &:hover{
      .gallery__img{
        opacity: .3;
      }
      i{
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
}

.menu__wrapper{
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @include media-breakpoint-only(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  .menu__box{
    .inner__box{
      position: relative;
      display: block;
      overflow: hidden;
      figure{
        margin-bottom: 0;
        a{
          display: block;
        }
      }
      h4{
        position: relative;
        display: block;
        font-size: 20px;
        font-weight: 700;
        color: $white;
      }
      .image{
        position: relative;
        display: block;
        overflow: hidden;
        background: $secondary;
        border-radius: 5px;
        &::before{
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.8)10%, rgba(0,0,0,0.2) 50%);
          left: 0px;
          bottom: 0px;
          z-index: 1;
          transition: all 500ms ease;
          pointer-events: none;
        }
        img{
          width: 100%;
          border-radius: 5px;
          transition: all 500ms ease;
          height: auto;
          max-width: 100%;
          border: none;
          box-shadow: none;
        }
      }
      a.icon__link{
        position: absolute;
        top: 20px;
        right: 20px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        background: #ffffff;
        font-size: 14px;
        text-align: center;
        border-radius: 50%;
        color: $secondary;
        z-index: 1;
        transition: all 500ms ease;
      }
      .text{
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        pointer-events: none;
        h4{
          position: absolute;
          left: 30px;
          bottom: 30px;
          opacity: 1;
          &::before, &::after{

            background: $primary;
            border-radius: 2px;
            position: absolute;
            content: '';
            height: 6px;
            top: -20px;
          }
          &::before{
            width: 6px;
            left: 0px;
          }
          &::after{
            width: 50px;
            left: 10px;
          }
        }
      }
      .overlay-content{
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding: 0px 20px 0px 30px;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: all 500ms ease;
        pointer-events: none;
        h4{
          padding-bottom: 10px;
        }
      }
      &:hover{
        .text{
          h4{
            opacity: 0;
          }
        }
        a.icon__link{
          color: #ffffff;
          box-shadow: 0px 0px 0px 5px rgba(228, 30, 47, 0.5);
          background: $primary;
        }
        .overlay-content{
          bottom: 33px;
          opacity: 1;
          visibility: visible;
          color: white;
        }
      }
    }
  }
}



.recipe__content{
  ol{
    margin-top: 30px;
    margin-bottom: 30px;
    list-style-type: none;
    counter-reset: ol-counter;
    >li{
      position: relative;
      padding-left: 30px;
      margin-bottom: 20px;
      &::before{
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 40px;
        height: 40px;
        top: 50%;
        margin-top: -20px;
        left: -30px;
        border: 2px solid #696b7e;
        color: #696b7e;
        border-radius: 20px;
        content: counter(ol-counter);
        counter-increment: ol-counter;
        background-color: white;
        box-shadow: 0px 0px 0px 5px rgba(176, 176, 176, 0.3);
        font-family: $roboto_black;
        z-index: 1;
      }
      &:not(:last-child){
        &::after{
          content: "";
          display: inline-block;
          position: absolute;
          left: -10px;
          height: 120%;
          width: 2px;
          top: 30px;
          background-color: #eeeeee;
        }
      }
    }
  }
}
