.app__blog{
  margin-bottom: 30px;
  .blog-list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .post-detail{
    .post__lead.lead{
      font-size: 18px;
      margin-bottom: 20px;
      font-style: italic;
      background-color: rgba($lighten, .3);
      border-radius: 20px 0;
      padding: 1rem;
      p:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.blog__item{
  position: relative;
  display: block;
  padding: 20px 20px;
  border: 1px solid $lighten;
  border-radius: 5px;
  background: #ffffff;
  margin-bottom: 30px;
  transition: all 500ms ease;
  img{
    border-radius: 5px;
  }
  h3{
    font-size: 20px;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;

    border-bottom: 1px solid $lighten;

    a{
      text-decoration: none;
      color: inherit;
    }
  }
  .post__categories{
    display: flex;
    padding-left: 0;
    gap: 1rem;
    list-style-type: none;
    a{
      text-decoration: none;
      color: inherit;
      display: inline-flex;
      padding: .2rem 0.5rem;
      border-radius: 5px;
      background-color: rgba($secondary, .1);
      font-size: 14px;
    }
  }
  .event__date{
    background-color: $primary;
    color: white;
    padding: 1rem;
    position: absolute;
    right: -1px;
    top: -1px;
    border-radius: 0px 5px 0px;
    font-family: $roboto_bold;
    text-align: center;
    span{
      display: inline-block;
      margin: 1px;
    }
    .date-range-to{
      display: block;
    }
  }
}

.blog__item__unpublished {
  opacity: .5;
}

.sidebar__blog{
  .plugin{
    padding: 20px 20px;
    border: 1px solid $lighten;
    border-radius: 5px;
    margin-bottom: 20px;
    h3{
      font-size: 20px;
      margin-bottom: 20px;
      color: $secondary;
    }
    ul{
      list-style: none;
      padding-left: 0;
      a{
        color: inherit;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        span{
          line-height: 1.2;
        }
      }
      ul{
        padding-left: 10px;
        margin-top: 10px;
      }
    }
    ul.blog-categories{
      &:last-child{
        margin-bottom: 0;
      }
      >li{
        position: relative;
        display: block;
        padding-bottom: 10px;
        line-height: 1;

        &:not(:last-child){
          border-bottom: 1px dashed $lighten;
          margin-bottom: 10px;

        }


      }
    }
    .ul__archive{
      >li>a{
        font-family: $roboto_bold;
      }
      ul{
        li{
          a{
            padding: 10px 0;
          }
          &:not(:last-child){
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
    .blog-tags{
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      a{
        background-color: #f5f5f5;
        display: flex;
        align-items: center;
        font-size: 12px;
        gap: 10px;
        padding: 5px 10px;
        border-radius: 10px;
        transition: all .3s ease;
        span{
          background-color: #d7d7d7 !important;

          color: #7b7b7b !important;

        }
        &:hover{
          background-color: darken(#f5f5f5, 50);
          color: white;

        }
      }
    }
  }
}
.post-detail__list{
  article.blog__item{
    margin-bottom: 0;
  }
}
.social-share-buttons {
  display: inline;
  a { text-decoration: none; }
}

