#id_registration{
  h1{
    margin-bottom: 1rem;
    font-size: 28px;
    letter-spacing: 1px;
  }
}

.login__wrapper{
  margin-top: -150px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}
.form{
  // background-color: rgba(white, .2);
  // padding: 2rem;
  // border-radius: 4px;
  .vertical-input-group .input-group:first-child * {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .vertical-input-group .input-group:last-child * {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .vertical-input-group .input-group:not(:first-child) * {
    border-top: 0;
  }
  .form-control:not(.form-control-sm){
    padding: .810rem .96rem;
    height: inherit;
  }
}
