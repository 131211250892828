.style_1{
  text-transform: uppercase;
  font-family: $roboto_bold;
  letter-spacing: 1px;
  color: $primary;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  &::before{
    content: "";
    width: 80px;
    height: 2px;
    background-color: $primary;
    display: inline-block;
  }
}

h2{
  font-size: 30px;
}
h3{
  font-size: 26px;
}
h4{
  font-size: 20px;
}
h5{
  font-size: 18px;
}
h6{
  font-size: 17px;
}


p{
  &.intro{
    font-family: $roboto_bold;
    font-size: 1.2rem;
  }
}


.content, .blog-content{
  h2{
    position: relative;
    font-size: 30px;
    margin-bottom: 3rem;
    &::before, &::after{
      background: $primary;
      border-radius: 2px;
      position: absolute;
      content: '';
      height: 6px;
      bottom: -20px;
    }
    &::before{
      width: 6px;
      left: 0px;
    }
    &::after{
      width: 50px;
      left: 10px;
    }
  }
  h3{
    font-size: 26px;
  }
  h4{
    font-size: 22px;
  }
}
