
.slick-slider{
  .slick-arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: 0;
    z-index: 1;
    span{
      background-size: cover;
      background-repeat: no-repeat;
      background-repeat: no-repeat;
      display: block;
    }
    &.prev{
      left: 20px;
    }
    &.next{
      right: 20px;
      span{
        transform: scale(-1);
      }
    }
  }
  .dots{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding-left: 0;
    button{
      border: 0;
      background-color: darken(white, 10);
      width: 10px;
      height: 10px;
      border-radius:10px;
      padding: 0;
      text-indent: 999999px;
    }
    .slick-active{
      button{
        background-color: $primary;
      }
    }
  }
}
.recipes__items{
  .dots{
    bottom: -60px;
  }
  .slick-arrow{
    span{
      background-image: url(../img/arrow-dark.svg);
      width: 10px;
      height: 18px;
      &::before{
        display: none;
      }
    }
    &.prev{
      left: -20px;
    }
    &.next{
      right: -20px;
      span{
        transform: scale(-1);
      }
    }
  }

}


.testimonials__items{
  text-align: center;
  margin: 10px auto;
  max-width: 800px;
  overflow: hidden;
  img{
    margin: 10px auto;
    border-radius: 50%;
    width: 100px;
  }
  .content{
    position: relative;
    padding-top: 1rem;
    margin-bottom: 1rem;
    &::before{
      display: inline-block;
      width: 25px;
      height: 22px;
      content: "";
      background-image: url(../img/testimonial.svg);
      background-repeat: no-repeat;
    }
    p{
      max-width: 600px;
      margin: 0 auto;
    }
  }
  h3{
    color: $primary;
    font-size: 20px;
  }
}


.reasons__carousel{
  .arrow{
    background-color: $secondary;
    color: white;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.10) inset, 0px 50px 100px -20px rgba(50, 50, 93, 0.25), 0px 30px 60px -30px rgba(0, 0, 0, 0.05);
  }
}
