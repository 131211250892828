html {
  scroll-behavior: smooth;
  font-size: 16px;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  display: flex;
  flex-direction: column;
}
main {
  flex: 1 0 auto;
  overflow-x: hidden;
}
#footer {
  flex-shrink: 0;
}


.breadcrumb{
  .breadcrumb-item{
    a{
      text-decoration: none;
      color: inherit;
    }
  }
}

.accordion{
  border: 1px solid #EEE;
}
.accordion-button:not(.collapsed){
  background-color: rgba($secondary, .2);
  color: inherit;
}

.accordion-header{
  button{
    font-size: 18px !important;
    &:focus{
      border: 0;
      outline: 0 !important;
      box-shadow: inherit !important;
    }
  }
}

.img-thumbnail{
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
  padding: 0rem;
  border: 0;
}
img.rounded{
  border-radius: 20px !important;
}


.test{

  width: 90px;
  height: 90px;
  line-height: 90px;
  background: #ffffff;
  text-align: center;
  font-size: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.5);
}


.oops{
  font-family: $roboto_black;
  font-size: 2rem;
  font-style: italic;
  opacity: .1;
}

#id_error_page{
  header{
    border-bottom: 1px solid $light;
  }
  .container{
    max-width: 900px;
  }
  main{
    display: flex;
    align-items: center;
  }
  h1{
    text-transform: uppercase;
    color: $primary;
    font-family: $roboto_black;
    letter-spacing: 1px;
    font-size: 28px;
  }
  .img__error{
    opacity: .3;
  }
}


.breadcrumb {
  li + li {
    margin-left: 5px;
    font-weight: bold;
    &::before {
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      content: "\f105" !important;
      display: inline-block;
      margin-right: 5px;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }
  }
}
#id_legalnotice{
  main{
    padding-top: 1rem;
  }
  h1{
    text-transform: uppercase;
    text-align: center;
    padding: 2rem;
    background-color: $light;
    font-size: 30px;
    border-radius: 10px;
    letter-spacing: 1px;
  }
  h2{
    color: $primary;
    font-size: 20px;
  }
  .legalnotice-list{
    >li{
      margin-bottom: 3rem;
    }
  }
}


.fobi-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  .form-button-wrapper{
    grid-column: 1/-1;
  }
  .form-group{
    margin-bottom: 10px;
    &.form-element-wrapper-adresse,
    &.form-element-wrapper-complement_dadresse,
    &.form-element-wrapper-message{
      grid-column: 1/-1;
    }
  }
  input.form-control{
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 0;
  }
  label{
    font-size: 90%;
    font-family: $roboto_bold;
    display: block;
    margin-bottom: 10px;
  }
}
.form-required-field-message-wrapper{
  background-color: rgba($primary, .3);
  padding: 10px;
  border: 1px solid $primary;
  margin-bottom: 20px;
  grid-column: 1/-1;
}
.required-field{
  color: $danger;
}



.form-required-field-message-wrapper{
  background-color: rgba(#74b9ff, .3);
  border: 1px solid #74b9ff;
  padding: 10px;
  color: #74b9ff;
  margin-bottom: 10px;
}

.form-button-wrapper{
  text-align: right;
}


.plugin__search{
  .form__wrapper{
    margin-bottom: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @include media-breakpoint-up(lg) {
      border-radius: 30px;
      padding: 3rem;
    }
    @include media-breakpoint-down(lg) {

    }
    h2{
      text-align: center;
    }
  }
  .search-results{
    .result-list{
      list-style-type: none;
      padding-left: 0;
      display: grid;
      gap: 10px;
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
      }
      li{
        border: 1px solid #eee;
        background-color: rgba($light, .1);
        // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 20px;
        margin-bottom: 5px;
        border-radius: 10px;
        p:last-child{
          margin-bottom: 0;
        }
        .highlighted{
          background-position: top left;
          background-repeat: no-repeat;
          background-size: 100% auto;
          transition: background-size .5s;
          background-image: linear-gradient(to top, rgba($primary, .2) 60%, transparent 0);
          font-weight: bold;
        }
      }
      h3{
        font-size: 20px;
        a{
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
}

.frm__search{
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
}



div[class^='col'], div[class*=' col']{
  @include media-breakpoint-down(sm) {
    min-width: 100%;
    &+div[class^='col'], &+div[class*=' col']{
      margin-top: 20px;
    }
  }
}
