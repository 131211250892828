.teaser{
  >a{
    color: inherit;
    display: block;
    text-decoration: none;
  }
}

.teaser-default{
  position: relative;

  .teaser-image{
    background-color: $dark;
    border-radius: 20px;
    overflow: hidden;
    img{
      border-radius: 20px;
      max-width: 100%;
      height: auto;
      opacity: .4;
      transition: all .3s ease;
    }
  }
  .teaser-content{
    padding: 30px;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    color: white;
  }
  &:hover{
    .teaser-image{
      img{
        opacity: .1;
        transform: scale(1.6);
      }
    }
    .btn{
      background-color: $primary;
      color: white;
      border-color: $primary;
    }
  }
}

.teaser-icon{
  background: $white;
  box-shadow: 0px 30px 60px -30px rgba(0, 0, 0, 0.3), 0px 50px 100px -20px rgba(50, 50, 93, 0.25), inset 0px 1px 1px rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 30px;
  text-align: center;
  .icon{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 75px;
    height: 75px;

    background: #F9F8F8;
    border-radius: 18px;
    position: relative;
    &::before{
      content: "";
      width: 29px;
      height: 29px;
      display: block;
      background-color: rgba($primary, .3);
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 50%;
      transition: all .3s ease;
    }
    span{
      width: 75px;
      height: 75px;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      &.eat{
        background-image: url(../img/restaurant.svg);
      }
      &.travel{
        background-image: url(../img/plane.svg);
      }
      &.product{
        background-image: url(../img/pizza.svg);
      }
    }


  }
  h3{
    text-transform: uppercase;
    color: $primary;
    font-size: 16px;

    letter-spacing: 2px;
  }
  &:hover{
    .icon::before{
      transform: scale(1.8);
      transform-origin: top right;
    }
  }
}
