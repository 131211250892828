.select2-container--default {
  .select2-results__option--highlighted[aria-selected] {
    background-color: var(--bs-primary);
  }

  .select2-search--dropdown .select2-search__field {
    border: 0;
    outline: solid 1px var(--bs-primary);
    padding-right: .5rem;
    padding-left: .5rem;
  }

  .select2-dropdown {
    border: var(--bs-border-width) solid var(--bs-border-color);
    box-shadow: 0 1rem 2rem rgba(0,0,0,.2);
  }

  .select2-selection--single,
  .select2-selection--multiple {
    height: auto;
    padding: .531rem .75rem;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:focus,
    &:focus-visible {
      border-color: #f18389;
      outline: 0;
      box-shadow: 0 0 0 .25rem rgba(227,6,19,.25);
    }

    .select2-selection__rendered {
      padding-right: 0;
      padding-left: 0;
      line-height: normal;
    }

    .select2-selection__arrow {
      --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
      width: calc(16px + .75rem);
      height: 100%;
      background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
      background-repeat: no-repeat;
      background-position: right .75rem center;
      background-size: 16px 12px;
      b { display: none; }
    }

    .select2-selection__placeholder {
      color: var(--bs-text-color);
    }

    .select2-selection__clear {
      font-weight: normal;
      margin-right: 1.75rem;
    }
  }
}

.cms-toolbar-expanded .select2-container--open:not(.select2) {
  margin-top: 46px;
}
